<script lang="ts">
  import Button from '../button/button.svelte';

  export let stars: number;
</script>

<Button
  variant="secondary"
  href="https://github.com/temporalio/temporal"
  target="_blank"
  leadingIcon="github"
  rel="noreferrer nofollow"
>
  <div class="flex flex-row items-center gap-4">
    {#if stars}
      {Intl.NumberFormat('en-US').format(stars)} Stars
    {:else}
      Star us on GitHub
    {/if}
  </div>
</Button>
